<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading">
			<form id="fasapayRedir" action="https://sci.fasapay.com/" method="post" name="payment" acceptcharset="utf-8">
				<input id="merchantAcc" type="hidden" name="fp_acc" />
				<input id="merchantStore" type="hidden" name="fp_store" />
				<input type="hidden" name="fp_item" value="Trading Deposit" />
				<input id="amount" type="hidden" name="fp_amnt" />
				<input id="currency" type="hidden" name="fp_currency" />
				<input type="hidden" name="fp_comments" value="Trading Deposit" />
				<input type="hidden" name="fp_fee_mode" value="FiR" />
				<input id="merchantReference" type="hidden" name="fp_merchant_ref" />
				<input id="successUrl" type="hidden" name="fp_success_url" />
				<input type="hidden" name="fp_success_method" value="GET" />
				<input id="errorUrl" type="hidden" name="fp_fail_url" />
				<input type="hidden" name="fp_fail_method" value="GET" />
				<input id="webhookUrl" type="hidden" name="fp_status_url" />
				<input type="hidden" name="fp_status_method" value="POST" />
			</form>
			<div class="cashier-box cashier-box--deposit">
				<payment-logo custom-logo="fasapay.png" />
				<div class="small-width overflow-visible">
					<payment-wallet />
				</div>

				<div class="line-separator-wrapper">
					<div class="line-separator" />
				</div>

				<amount-buttons
					:buttons-value="currentMethod.predefinedDepositAmounts"
					:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
					:currency="currency"
					:form-errors="formErrors['amount']"
					:current-method="currentMethod"
					:overridden-min-amount="minAmount"
					@set-button-value="setButtonValue"
					@input-amount-value-validation="setFormValidation"
				/>

				<button type="button" class="button primary-button deposit center" :disabled="!form.validated" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>

				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</div>
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositFasapay',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
				promocode: null,
				validated: true,
			},
			merchantAcc: null,
			merchantStore: null,
			currency: 'USD',
			minAmount: null,
			formErrors: {
				amount: null,
			},
			loading: true,
			paymentDone: false,
		};
	},
	computed: {
		...mapGetters(['isEmbedded']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('fasapay');
		},
	},
	async mounted() {
		await this.getConfig();
		await this.getUserWallet();
		this.loading = false;
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		setFormValidation(event) {
			this.form.validated = event;
		},
		async getConfig() {
			const response = await apiClient.fasapayGetConfig();

			if (response.status === 200) {
				this.form.amount = response.data.config.preselectedValue;
				this.buttonValues = response.data.config.buttonValues;
				this.currency = response.data.config.currency;
				this.minAmount = response.data.config.buttonValues[0];
				this.merchantAcc = response.data.config.merchantAcc;
				this.merchantStore = response.data.config.merchantStore;
			} else {
				throw new Error('Invalid response from get config');
			}
		},
		async createTransaction(amount, currency, params) {
			const response = await apiClient.fasapayCreateTransaction(this.walletId, amount, currency, params);

			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},

		async pay() {
			this.loading = true;

			try {
				document.getElementById('merchantAcc').value = this.merchantAcc;
				document.getElementById('merchantStore').value = this.merchantStore;
				document.getElementById('amount').value = this.form.amount;
				document.getElementById('currency').value = this.currency;
				document.getElementById(
					'successUrl'
				).value = `${window.location.origin}/payments/deposit/confirmation?module=Deposit&method=generic&status=success`;
				document.getElementById(
					'errorUrl'
				).value = `${window.location.origin}/payments/deposit/confirmation?module=Deposit&method=generic&status=error`;
				document.getElementById('webhookUrl').value = `${process.env.VUE_APP_URL}/payment/fasapay/webhook`;

				const form = document.getElementById('fasapayRedir');
				const formData = new FormData(form);
				const params = new URLSearchParams(formData);

				const result = await this.createTransaction(this.form.amount, this.currency, params.toString());

				document.getElementById('merchantReference').value = result.transactionId;

				if (this.isEmbedded) {
					form.setAttribute('target', '_blank');
					const walletId = this.$route.params.walletId;

					this.$router.push({
						path: `/payments/deposit/${walletId}/confirmation`,
						query: { status: 'external' },
					});
				}

				form.submit();
			} catch (e) {
				const walletId = this.$route.params.walletId;

				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
				console.error('Payment failed', e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped>
#fasapayRedir {
	visibility: hidden;
}
</style>
